import React, { createContext, useContext, useState, useCallback } from "react";
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authModal, setAuthModal] = useState({
    state: sessionStorage.getItem("expired") ?? 0,
    message: sessionStorage.getItem("expiredMsg") ?? "",
  });
  var token = null;
  if (sessionStorage.getItem("jwt")) token = sessionStorage.getItem("jwt");
  var decoded = undefined;
  if (sessionStorage.getItem("jwt")) decoded = jwt_decode(token);

  const isUserAuthenticated = () => {
    if (decoded?.exp * 1000 < Date.now()) {
      setAuthModal({ state: 1, message: "expired.text" });
      sessionStorage.setItem("expired", 1);
      sessionStorage.setItem("expiredMsg", "expired.text");
      sessionStorage.removeItem("jwt");
      return false;
    } else {
      return token !== null;
    }
  };

  const checkRole = () => {
    if (decoded && decoded?.exp * 1000 > Date.now()) {
      return decoded.roles;
    } else {
      return [];
    }
  };

  const checkUserId = () => {
    if (decoded && decoded?.exp * 1000 > Date.now()) {
      return decoded.userId;
    } else {
      return [];
    }
  };

  const [isAuthenticated, setIsAuthenticated] = useState(isUserAuthenticated());
  const [roles, setRoles] = useState(checkRole());
  const [userId, setUserId] = useState(checkUserId());

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setRoles([]);
    setUserId(undefined);
    sessionStorage.removeItem("jwt");
  }, [setIsAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        roles,
        setRoles,
        authModal,
        setAuthModal,
        setUserId,
        userId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
