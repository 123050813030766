import React from "react";
import { Typography, Container } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import CollapsibleTable from "./TableFinancialCollaterals";

const FinancialCollateralsComponent = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: "1rem" }}>
      <Typography
        variant="h1"
        style={{
          color: "#F36E1E",
          padding: "10px",
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "2rem",
        }}
      >
        {t("myNFTs.misColaterales")}
      </Typography>

      <CollapsibleTable />
    </Container>
  );
};

export default FinancialCollateralsComponent;
