import React, { useEffect, useState } from "react";
import "./MarketplaceStats.css";
import MarketplaceStatsRepository from "../../../data/repository/MarketplaceStatsRepository";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const MarketplaceStats = ({ data }) => {
  const [t, i18n] = useTranslation();

  const getStats = new MarketplaceStatsRepository();

  const [stats, setStats] = useState();

  const getStatsData = async () => {
    try {
      const response = await getStats.getMarketplaceStats();
      setStats(response);
    } catch (error) {
      console.error("Error fetching cellars:", error);
    }
  };

  useEffect(() => {
    getStatsData();
  }, []);

  return stats ? (
    <Container id="stats-landing" maxWidth="xl">
      <div className="stats-container">
        <Grid
          container
          spacing={4}
          px={0}
          mt={10}
          mx={0}
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontSize: { xs: "20px", sm: "40px" },
                fontWeight: "bold",
              }}
              align="center"
            >
              {t("home.stats.title")}
            </Typography>
          </Grid>
          <Grid item xs={0} sm={2} sx={{ paddingLeft: "0px !important" }} />
          <Grid
            item
            xs={12}
            sm={8}
            mb={2}
            sx={{ paddingLeft: "0px !important" }}
          >
            <Typography
              variant="body1"
              sx={{ color: "white", fontSize: { xs: "14px", sm: "20px" } }}
              align="center"
            >
              {t("home.stats.description")}
            </Typography>
          </Grid>
          <Grid item xs={0} sm={2} sx={{ paddingLeft: "0px !important" }} />

          <Box
            sx={{
              paddingLeft: "0px !important",
              width: "calc(100% - 32px)",
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <table>
              <thead>
                <tr>
                  <th>{t("home.stats.litresTokenized")}</th>
                  <th>{t("home.stats.amountOperations")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{stats.stats.liters}L</td>
                  <td>${stats.stats.amount}</td>
                </tr>
              </tbody>
            </table>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "white", marginTop: 4 }}
              align="center"
            >
              {t("home.stats.titleTable")}
            </Typography>
            <table>
              <thead>
                <tr>
                  <th>{t("home.stats.winnery")}</th>
                  <th>{t("home.stats.liters")}</th>
                  <th>{t("home.stats.entity")}</th>
                </tr>
              </thead>
              <tbody>
                {stats?.last?.map((m, index) => (
                  <tr key={index}>
                    <td>{m.cellar.name}</td>
                    <td>{m.liters}</td>
                    <td>{m.financial.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Grid>
      </div>
    </Container>
  ) : null;
};

export default MarketplaceStats;
