import apiToken from "../apiToken";

class CheckoutRepository {
  async prepareMetamask() {
    try {
      const response = await apiToken.get("checkout/metamask");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async prepareMercadopago() {
    try {
      const response = await apiToken.get("checkout/mercadopago", {
        params: {
          referrer: window.sessionStorage.getItem("referrer"),
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async prepareZenkipay() {
    try {
      const response = await apiToken.post("checkout/zenkipay", {
        referrer: window.sessionStorage.getItem("referrer"),
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createMetamaskOrder(externalReference) {
    try {
      const response = await apiToken.post("/generate/metamask/order", {
        externalReference: externalReference,
      });

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error generating user order:", error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  }

  async createOrder() {
    try {
      const response = await apiToken.get("order/create");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async checkOrder(internalReference) {
    try {
      const response = await apiToken.get("order/check/" + internalReference);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async checkCode(code) {
    try {
      const response = await apiToken.post("discount/apply", { code: code });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async prepareStripe() {
    try {
      const response = await apiToken.get("checkout/stripe");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default CheckoutRepository;
