import React, { useState, useEffect, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./buttonstripe.css";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MercadopagoIcon from "../../../assets/images/icono-mercadopago.png";
import { useCart } from "../../contexts/CartContext";
import ErrorDialog from "../ErrorDialog";
import CheckoutRepository from "../../../data/repository/CheckoutRepository";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import { useNavigate } from "react-router-dom";
import StripeLogo from "./stripeLogo";

const CartCheckout = () => {
  const [t, i18n] = useTranslation();

  const navigate = useNavigate();

  const { cartList, getCartList, addProduct, subtractProduct } = useCart();
  const checkoutRepository = new CheckoutRepository();

  const [error, setError] = useState(null);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(null);
  const [codeApplied, setCodeApplied] = useState({
    exclusive: false,
    code: "",
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [codeHidden, setCodeHidden] = useState(false);
  const [zenkiModal, setZenkiModal] = useState(null);

  function handleZenkipayEvents(error, data) {
    if (error) {
      return console.error(error);
    }
    if (data) {
      if (
        data.isCompleted &&
        data.postMsgType === "shopper_payment_confirmation"
      ) {
        setZenkiModal(t("cartCheckout.zenkiOngoing"));
      }
      if (data.postMsgType === "done") {
        navigate("/payment/wait");
      }
      if (data.postMsgType === "cancel") {
        setError(t("cartCheckout.zenkiCancel"));
      }
    }
  }

  initMercadoPago(process.env.REACT_APP_MERCADOPAGO_KEY, {
    locale: i18n.language === "es" ? "es-AR" : "en-US",
  });

  /*const sendPayment = async () => {
        if (!termsAccepted) {
            setError(t('cartCheckout.checkoutTerms'));
            return;
        }

        var checkoutData;

        try {
            checkoutData = await checkoutRepository.prepareMetamask();
            if (checkoutData) {
                setCodeHidden(true);
            }
        } catch (error) {
            console.error("Error obteniendo preferenceId:", error);
            setError( error.response?.data?.message === "checkout.error.00001" ? t('cartCheckout.checkoutExclusiveError') : error.response?.data?.message === "checkout.error.00002" ? t('cartCheckout.checkoutDiscountError') : 'Error al procesar el pago con Metamask');
        }

        if (checkoutData) {

            if (typeof window.web3 !== 'undefined') {
                const oldProvider = window.web3.currentProvider;
                window.web3 = new window.Web3(oldProvider);
            } else {
                setError("You do not have the Metamask extension installed. First install Metamask and reload this page.");
                return;
            }
    
            let hex_chainId = window.web3.utils.toHex('1');
            var clientAddress = '';

            try {
                await window.web3.currentProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: hex_chainId }],
                });
            } catch (error) {
                console.log(error)
                if (error.code === 4902) {
                    try {
                        await window.web3.currentProvider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                        {
                        chainId: hex_chainId,
                        chainName: "Ethereum Mainnet",
                        rpcUrls: ["https://mainnet.infura.io/v3/"],
                        nativeCurrency: {
                            name: "ETH",
                            symbol: "ETH",
                            decimals: 18,
                        },
                        blockExplorerUrls: ["https://etherscan.io"],
                        },
                        ],
                        });
                    } catch (error) {
                        alert(error.message);
                    }
                }
            }

            async function getAccounts(callback) {
                await window.web3.eth.getAccounts(async(error,result) => {
                    if (error) {
                        console.error(error);
                    } else {
                        if (result.length === 0) {
                            try {
                                await window.ethereum.request({ method: 'eth_requestAccounts' }).then(function(request_accounts) {
                                    callback(request_accounts[0]);
                                });
                            } catch (error) {
                                alert(error.message);
                            }
                        } else {
                            callback(result[0]);
                        }
                    }
                });
            }

            getAccounts(async function(result) {
                if (result) {
                    try {
                        const price_mul = checkoutData.amount * 1000000;
                        const BN = window.web3.utils.BN;
                        const addressContract = process.env.REACT_APP_METAMASK_ADDRESS;
                        const contractTrader = new window.web3.eth.Contract(usdcAbi, addressContract);
                        const price = (new BN(price_mul));
                
                        let transactionsParameters = {
                            from: result,
                            to: addressContract,
                            data: contractTrader.methods.transfer(
                                checkoutData.address,
                                price
                            ).encodeABI()
                        }
                
                        await window.web3.eth.sendTransaction(transactionsParameters)
                            .on('transactionHash', async function (hash) {
                                const response = await checkoutRepository.createMetamaskOrder(hash);
                
                                if (response.success) {
                                    navigate('/payment/wait/' + response.internalReference);
                                    return;
                                }
                
                                setError(response.message);
                            })
                            .on('error', function(error) {
                                if (error.code === 4001) {
                                    setError('Has rechazado la transacción');
                                } else {
                                    setError(error.message);
                                }
                            });
                    } catch (error) {
                        console.error(error)
                    }
                }
            });
    
            /*window.web3.eth.getAccounts(function(err, accounts) {
                if (accounts.length === 0) {
                    window.ethereum.request({ method: 'eth_requestAccounts' }).then(function(request_accounts) {
                        console.log(request_accounts[0])
                        setClientAddress(request_accounts[0]);
                    });
                } else {
                    console.log(accounts[0])
                    setClientAddress(accounts[0]);
                }
            });
    
        }


    };*/

  const getPreferenceId = async (terms) => {
    if (!terms) {
      setError(t("cartCheckout.checkoutTerms"));
      return;
    } else {
      try {
        const preferenceIdResponse =
          await checkoutRepository.prepareMercadopago();
        if (preferenceIdResponse) setCodeHidden(true);
        return preferenceIdResponse;
      } catch (error) {
        console.error("Error obteniendo preferenceId:", error);
        setError(
          error.response?.data?.message === "checkout.error.00001"
            ? t("cartCheckout.checkoutExclusiveError")
            : error.response?.data?.message === "checkout.error.00002"
            ? t("cartCheckout.checkoutDiscountError")
            : t("cartCheckout.checkoutError") + "MercadoPago"
        );
      }
    }
  };

  const onError = async (error) => {
    // callback called for all Brick error cases
    console.error(error);
  };

  const onReady = async () => {
    /*
        Callback called when Brick is ready.
        Here you can hide loadings from your site, for example. 
    */
  };

  const handleOnSubmit = useCallback(() => {
    return getPreferenceId(termsAccepted);
  }, [termsAccepted]);

  const handleOnError = useCallback((error) => {
    onError(error);
  }, []);

  const handleOnReady = useCallback(() => {
    onReady();
  }, []);

  const getZenkipay = async () => {
    if (!termsAccepted) {
      setError(t("cartCheckout.checkoutTerms"));
      return;
    }

    try {
      const zenpayResponse = await checkoutRepository.prepareZenkipay();
      if (zenpayResponse) setCodeHidden(true);
      const zenkipayOptions = {
        orderId: zenpayResponse.orderId,
        paymentSignature: zenpayResponse.paymentSignature,
      };
      window.zenkipay.openModal(zenkipayOptions, handleZenkipayEvents);
    } catch (error) {
      console.error("Error obteniendo zenkipay data:", error);
      setError(
        error.response?.data?.message === "checkout.error.00001"
          ? t("cartCheckout.checkoutExclusiveError")
          : error.response?.data?.message === "checkout.error.00002"
          ? t("cartCheckout.checkoutDiscountError")
          : t("cartCheckout.checkoutError") + "Zenkipay"
      );
    }
  };

  const handleAddProduct = async (productId) => {
    addProduct(productId);
  };

  const handleSubtractProduct = async (productId, tokenId) => {
    subtractProduct(productId, tokenId);
  };

  const handleCode = async (e) => {
    e.preventDefault();
    if (code) {
      try {
        const response = await checkoutRepository.checkCode(code);
        if (response) {
          getCartList();
        }
      } catch (error) {
        setCodeError(
          error.response?.data?.message === "discount.error.00001"
            ? t("cartCheckout.discountNotFound")
            : error.response?.data?.message === "discount.error.00002"
            ? t("cartCheckout.discountInsufficientQuantity")
            : error.message
        );
      }
      setCode("");
    }
  };

  useEffect(() => {
    if (cartList?.products?.length) {
      var preCode = "";
      var exclusiveCode = false;
      for (let i = 0; i < cartList.products.length; i++) {
        if (cartList.products[i].discount) {
          preCode = cartList.products[i].discount.code;
        }
        if (cartList.products[i].discount && cartList.products[i].exclusive) {
          exclusiveCode = true;
        }
      }
      setCodeApplied({ exclusive: exclusiveCode, code: preCode });
    }
  }, [cartList]);

  useEffect(() => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = "https://resources.zenki.fi/zenkipay/script/v2/zenkipay.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleStripe = async (e) => {
    e.preventDefault();
    try {
      const response = await checkoutRepository.prepareStripe();
      if (response) {
        window.location.href = response.url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ paddingBottom: "1rem" }}>
      <Typography
        variant="h1"
        style={{
          color: "#F36E1E",
          padding: "10px",
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "2rem",
        }}
      >
        {t("cartCheckout.myCart")}
      </Typography>
      <Typography
        variant="body1"
        paragraph
        style={{ marginTop: "4rem", fontSize: "20px", fontWeight: "bold" }}
      >
        {t("cartCheckout.myCartProducts")}
      </Typography>

      <Paper
        elevation={3}
        style={{ overflowX: "auto", marginTop: "20px", marginBottom: "1rem" }}
      >
        <Table style={{ minWidth: "580" }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "100px",
                  minWidth: "100px",
                  fontWeight: "bold",
                  color: "#F36E1E",
                }}
              >
                {t("cartCheckout.myCart1")}
              </TableCell>
              <TableCell
                sx={{ width: "100%", fontWeight: "bold", color: "#F36E1E" }}
              >
                {t("cartCheckout.myCart2")}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  width: "132px",
                  minWidth: "132px",
                  maxWidth: "132px",
                  display: { xs: "none", md: "table-cell" },
                  fontWeight: "bold",
                  color: "#F36E1E",
                }}
              >
                {t("cartCheckout.myCart3")}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  width: "132px",
                  minWidth: "132px",
                  maxWidth: "132px",
                  display: { xs: "none", md: "table-cell" },
                  fontWeight: "bold",
                  color: "#F36E1E",
                }}
              >
                {t("cartCheckout.myCart4")}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  width: "132px",
                  minWidth: "132px",
                  maxWidth: "132px",
                  fontWeight: "bold",
                  color: "#F36E1E",
                }}
              >
                {t("cartCheckout.myCart5")}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  width: "24px",
                  maxWidth: "24px",
                  fontWeight: "bold",
                  color: "#F36E1E",
                }}
              >
                {t("cartCheckout.myCart6")}
              </TableCell>
              <TableCell sx={{ width: "24px", maxWidth: "24px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartList?.products?.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{ width: "100px", minWidth: "100px" }}>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      handleSubtractProduct(item.id, item.token?.id)
                    }
                    style={{ color: "#F36E1E" }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  {item.quantity}
                  {item.token ? null : (
                    <IconButton
                      color="primary"
                      onClick={() => handleAddProduct(item.id)}
                      style={{ color: "#F36E1E" }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    minHeight: "50px",
                    width: "132px",
                    minWidth: "132px",
                    maxWidth: "132px",
                    display: "table-cell",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "start",
                      textAlign: "left",
                      maxWidth: "380px",
                      margin: "0rem",
                      gap: "0.8rem",
                      minHeight: "36px",
                    }}
                  >
                    <img
                      src={item.thumbnail}
                      alt={`thumb ${index}`}
                      style={{
                        width: "36px",
                        height: "36px",
                        objectFit: "contain",
                      }}
                    />
                    {item.token?.tokenNumber
                      ? "#" + item.token?.tokenNumber + " - " + item.name
                      : item.name +
                        (item.exclusive
                          ? " - " + t("cartCheckout.exclusive")
                          : "")}
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    minHeight: "50px",
                    width: "132px",
                    minWidth: "132px",
                    maxWidth: "132px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "380px",
                      margin: "0rem",
                    }}
                  >
                    <div>
                      {`${item.local_symbol}${item.price_local.toFixed(2)}`}
                    </div>
                    {/*<div style={{ fontSize: '11px', fontWeight: '400' }}>
                                            {item.price.toFixed(2)} USDC
                                        </div>*/}
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    width: "132px",
                    minWidth: "132px",
                    maxWidth: "132px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "380px",
                      margin: "0rem",
                    }}
                  >
                    <div>
                      {`${item.local_symbol}${item.taxes_local.toFixed(2)}`}
                    </div>
                    {/*<div style={{ fontSize: '11px', fontWeight: '400' }}>
                                            {item.taxes.toFixed(2)} USDC
                                        </div>*/}
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    width: "132px",
                    minWidth: "132px",
                    maxWidth: "132px",
                    color: "#F36E1E",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "380px",
                      margin: "0rem",
                    }}
                  >
                    <div>
                      {`${item.local_symbol}${item.total_local.toFixed(2)}`}
                    </div>
                    {/*<div style={{ fontSize: '11px', fontWeight: '400' }}>
                                            {item.total.toFixed(2)} USDC
                                        </div>*/}
                  </div>
                </TableCell>
                <TableCell sx={{ width: "24px", maxWidth: "24px" }}>
                  <IconButton
                    color="primary"
                    onClick={() => window.open(item.terms_url, "_blank")}
                    style={{ color: "#F36E1E", padding: "0rem" }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ width: "24px", maxWidth: "24px" }}>
                  {/*<IconButton
                                        color="primary"
                                        onClick={() => handleSubtractProduct(item.id)}
                                        style={{ color: '#F36E1E', padding: '0rem' }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>*/}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {codeHidden ? null : (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            maxWidth: "380px",
            marginLeft: "auto",
            gap: "1rem",
          }}
        >
          <TextField
            fullWidth
            size="small"
            sx={{ maxHeight: "40px" }}
            type="text"
            label={t("cartCheckout.code")}
            name="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{ style: { borderRadius: "55px" } }}
            helperText={null}
            error={null}
          />
          <Button
            variant="contained"
            color="primary"
            className={"btn-outline-gradient"}
            onClick={(e) => handleCode(e)}
            sx={{ maxHeight: "40px" }}
            disabled={!code}
          >
            {t("cartCheckout.send")}
          </Button>
        </Box>
      )}
      {cartList?.products?.reduce((n, { quantity }) => n + quantity, 0) ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginTop: "0px",
            maxWidth: "450px",
            marginLeft: "auto",
          }}
        >
          {codeApplied.code ? (
            <Typography
              variant="h6"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "right",
                marginTop: "0.5rem",
              }}
            >
              {`${t("cartCheckout.codeApplied")} ${codeApplied.code}${
                codeApplied.exclusive
                  ? " - " + t("cartCheckout.exclusiveCode")
                  : ""
              }`}
            </Typography>
          ) : null}
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              textAlign: "right",
              marginTop: "1rem",
            }}
          >
            {`${t("cartCheckout.total")}${
              cartList?.total[1]?.currency
            }${cartList?.total[1]?.value?.toFixed(2)}`}
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "14px", fontWeight: "400" }}
          >
            {t("cartCheckout.local")}
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "11px", fontWeight: "400", textAlign: "right" }}
          >
            <Trans
              i18nKey="cartCheckout.fees"
              components={{ important: <strong /> }}
            />
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "11px", fontWeight: "400", textAlign: "right" }}
          >
            {t("cartCheckout.conversionFees")}
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={<Checkbox color="primary" />}
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <Typography
              display="inline"
              mr={0.5}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              {`${t("cartCheckout.terms1")}${t("cartCheckout.terms2")}`}
            </Typography>
            {/*<Typography display="inline" variant="p" sx={{ cursor: 'pointer', fontWeight: 'bold', color: '#F36E1E', "&:hover": { opacity: 0.8 }, "&:active": { opacity: 0.6 } }}
                            onClick={() => window.open("/terms", "_blank")}
                        >
                            {`${t('cartCheckout.terms2')}`}
                    </Typography>*/}
          </Box>

          {cartList?.connectionInfo?.iso === "US" ? (
            <Grid container mt={2} spacing={0} alignItems="center">
              <Grid item xs={12}>
                <button
                  disabled={!termsAccepted}
                  className="stripe-connect"
                  onClick={handleStripe}
                >
                  <span>{t("cartCheckout.payWithStripe")}</span>
                  <StripeLogo fill={!termsAccepted ? "#e1e1e1" : "#fff"} />
                </button>
              </Grid>
            </Grid>
          ) : (
            <Grid container mt={2} spacing={0} alignItems="center">
              <Grid item xs={2}>
                <img
                  src={MercadopagoIcon}
                  alt="Mercadopago"
                  style={{
                    width: "56px",
                    marginTop: "32px",
                    marginBottom: "32px",
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Wallet
                  onSubmit={handleOnSubmit}
                  onReady={handleOnReady}
                  onError={handleOnError}
                />
              </Grid>

              {/*<Grid item xs={2}>
                            <img src={MetamaskIcon} alt="Metamask" style={{ width: '48px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={sendPayment}
                                className={"btn-outline-gradient"}
                                fullWidth
                            >
                                {t('cartCheckout.payMethod3')}
                            </Button>
                        </Grid>*/}
            </Grid>
          )}
        </Box>
      ) : null}

      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        alertTitle={t("cartCheckout.paymentError")}
        alertMessage={error}
        dark={false}
      />
      <ErrorDialog
        open={!!codeError}
        onClose={() => setCodeError(null)}
        alertTitle={t("cartCheckout.codeError")}
        alertMessage={codeError}
        dark={false}
      />
      <ErrorDialog
        open={!!zenkiModal}
        onClose={() => setZenkiModal(null)}
        alertTitle={t("cartCheckout.zenkiPending")}
        alertMessage={zenkiModal}
        dark={false}
      />
    </Container>
  );
};

export default CartCheckout;
