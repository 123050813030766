import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import UserRepository from "../../data/repository/UserRepository";
import UserLogin from "../../core/entity/UserLogin";
import { useAuth } from "../contexts/AuthContext";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import RequestPassword from "../../core/entity/RequestPassword";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import { app } from "../../firebaseConfig";
import BtnLoginGoogle from "./BtnLoginGoogle";

const LoginModal = ({ open, onClose, theme }) => {
  const { t } = useTranslation();
  const isDarkTheme = theme === "dark";
  const textColor = isDarkTheme ? "white" : "#160F31";
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formRecovery, setFormRecovery] = useState({
    email: "",
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [error, setError] = useState(null);
  const repository = new UserRepository();
  const { isAuthenticated, setIsAuthenticated, setRoles, setUserId } =
    useAuth();
  const [showActivateAccountMessage, setShowActivateAccountMessage] =
    useState(false);
  const [showRecoveryForm, setShowRecoveryForm] = useState(false);
  const navigate = useNavigate();
  const [emailGoogle, setEmailGoogle] = useState("");
  //const googleLogin = useRef(null);

  const auth = getAuth(app);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleInputRecoveryChange = (event) => {
    const { name, value } = event.target;
    setFormRecovery((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleActivationLinkClick = async () => {
    const response = await repository.resendActivation(
      formData.email?.trim() || emailGoogle
    );

    if (response.success) {
      setError(null);
      setShowActivateAccountMessage(false);
      onClose();
      await navigate("/", {
        state: {
          showMessage: true,
          message:
            "Te hemos enviado un correo electrónico con un enlace de activación. Por favor, revisa tu correo para activar la cuenta",
        },
      });
    } else {
      setError(response.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const login = new UserLogin(formData);
    let validationErrors = login.validateUser(formData);

    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      return;
    }

    try {
      setFieldErrors({});
      const response = await repository.login(login);

      if (response.success) {
        setError(null);
        sessionStorage.setItem("jwt", response.jwt);
        setIsAuthenticated(!isAuthenticated);
        setRoles(jwt_decode(response.jwt).roles);
        setUserId(jwt_decode(response.jwt).userId);
      } else {
        setError(response.message);

        if (403 === response.code) {
          setShowActivateAccountMessage(true);
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmitPasswordRecovery = async (e) => {
    e.preventDefault();

    const recovery = new RequestPassword(formRecovery.email);
    let validationErrors = recovery.validate(formRecovery);

    setFieldErrors({});
    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      return;
    }

    try {
      setFieldErrors({});
      const response = await repository.sendRecoveryPassword(formRecovery);

      if (response.success) {
        setShowActivateAccountMessage(false);
        onClose();
        await navigate("/", {
          state: {
            showMessage: true,
            message:
              "Se ha enviado un email a tu correo electrónico con un link para cambiar tu contraseña.",
          },
        });
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const loginGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, provider);
    if (res) {
      setEmailGoogle(res.user.email);
      try {
        const response = await repository.googleLogin({
          token: res?._tokenResponse?.idToken,
          firstName: res?._tokenResponse?.firstName,
          lastName: res?._tokenResponse?.lastName,
        });
        if (response.success) {
          setError(null);

          if (response.data.jwt) {
            sessionStorage.setItem("jwt", response.data.jwt);
            setIsAuthenticated(!isAuthenticated);
            setRoles(jwt_decode(response.data.jwt).roles);
            setUserId(jwt_decode(response.data.jwt).userId);
          } else {
            onClose();
            await navigate("/register-user", {
              state: {
                user: response.data,
                token: response.data?.token,
                social: "google",
              },
            });
          }
        } else {
          setError(response.message);

          if (403 === response.code) {
            setShowActivateAccountMessage(true);
          }
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent
        style={{ backgroundColor: isDarkTheme ? "#160F31" : "white" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            style={{ color: textColor, fontWeight: "bold" }}
            gutterBottom
          >
            {t("login.header")}
          </Typography>

          {error && (
            <Typography
              variant="body1"
              style={{ color: "red", marginTop: "1rem", marginBottom: "1rem" }}
            >
              {error}
            </Typography>
          )}

          {showRecoveryForm ? (
            <form
              onSubmit={handleSubmitPasswordRecovery}
              style={{ width: "100%", marginTop: "1rem" }}
            >
              <TextField
                fullWidth
                label={t("login.recoveryForm.emailLabel")}
                name="email"
                type="email"
                value={formRecovery.email}
                onChange={handleInputRecoveryChange}
                className={isDarkTheme ? "white-border" : "dark-border"}
                InputProps={{
                  style: {
                    color: textColor,
                    backgroundColor: "transparent",
                    borderRadius: "55px",
                    borderColor: textColor,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: textColor,
                  },
                }}
                helperText={fieldErrors.email}
                error={Boolean(fieldErrors.email)}
              />

              <Button
                type={"submit"}
                variant="outlined"
                className={"btn-gradient"}
                style={{ width: "100%", marginTop: "1rem" }}
              >
                {t("login.recoveryForm.submitButton")}
              </Button>
            </form>
          ) : (
            <>
              {showActivateAccountMessage ? (
                <Typography
                  variant="body1"
                  style={{
                    color: textColor,
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  {t("login.activationMessage.part1")}
                  <span
                    style={{
                      color: textColor,
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={handleActivationLinkClick}
                  >
                    {" "}
                    {t("login.activationMessage.linkText")}
                  </span>
                  .
                </Typography>
              ) : (
                <>
                  {!showLoginForm ? (
                    <>
                      <Button
                        variant="outlined"
                        className={
                          isDarkTheme
                            ? "gradient-border-button"
                            : "btn-outline-gradient"
                        }
                        sx={{
                          width: "100%",
                          maxWidth: "232px",
                          marginBottom: "1rem",
                          color: "white",
                        }}
                        onClick={() => setShowLoginForm(true)}
                      >
                        {t("login.loginOptions.emailLogin")}
                      </Button>
                      {/* <span style={{ marginBottom: '1rem', color: 'white' }}>
                        - o -
                      </span> */}
                      <BtnLoginGoogle onclick={() => loginGoogle()} />
                    </>
                  ) : (
                    <form
                      onSubmit={handleSubmit}
                      style={{ width: "100%", marginTop: "1rem" }}
                    >
                      <TextField
                        fullWidth
                        label={t("login.loginForm.emailLabel")}
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className={isDarkTheme ? "white-border" : "dark-border"}
                        InputProps={{
                          style: {
                            color: textColor,
                            backgroundColor: "transparent",
                            borderRadius: "55px",
                            borderColor: textColor,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: textColor,
                          },
                        }}
                        helperText={fieldErrors.email}
                        error={Boolean(fieldErrors.email)}
                      />

                      <TextField
                        fullWidth
                        label={t("login.loginForm.passwordLabel")}
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className={isDarkTheme ? "white-border" : "dark-border"}
                        style={{ marginTop: "1rem" }}
                        InputProps={{
                          style: {
                            color: textColor,
                            backgroundColor: "transparent",
                            borderRadius: "55px",
                            borderColor: textColor,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: textColor,
                          },
                        }}
                        helperText={fieldErrors.password}
                        error={Boolean(fieldErrors.password)}
                      />

                      <Button
                        type={"submit"}
                        variant="outlined"
                        className={"btn-gradient"}
                        style={{ width: "100%", marginTop: "2rem" }}
                      >
                        {t("login.loginForm.submitButton")}
                      </Button>
                    </form>
                  )}
                </>
              )}
            </>
          )}

          <Typography
            variant="body2"
            mt={2}
            style={{ fontStyle: "italic", color: textColor }}
            onClick={onClose}
          >
            {t("login.notMember.message")}
            <Link
              to="/register-user"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {t("login.notMember.linkText")}
              </span>
            </Link>
          </Typography>

          <Typography
            variant="body2"
            mt={0}
            style={{ fontStyle: "italic", color: textColor }}
            onClick={onClose}
          >
            {t("login.commerce.message")}
            <Link
              to="/register-commerce"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {t("login.commerce.linkText")}
              </span>
            </Link>
          </Typography>

          <Typography
            variant="body2"
            mt={0}
            style={{ fontStyle: "italic", color: textColor }}
            onClick={onClose}
          >
            {t("login.winery.message")}
            <Link
              to="/register-winery"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {t("login.winery.linkText")}
              </span>
            </Link>
          </Typography>

          <Typography
            variant="body2"
            mt={0}
            style={{ fontStyle: "italic", color: textColor }}
            onClick={onClose}
          >
            {t("login.financial.message")}
            <Link
              to="/register-financial"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {t("login.financial.linkText")}
              </span>
            </Link>
          </Typography>

          <Typography
            mt={1}
            variant="body2"
            onClick={() => setShowRecoveryForm(true)}
            style={{
              fontStyle: "italic",
              textDecoration: "underline",
              color: textColor,
            }}
          >
            {t("login.forgotPassword")}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
