import React from "react";
import {
  Typography,
  Grid,
  Card,
  Box,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const MarketplaceCard = (props) => {
  const { t } = useTranslation();

  return (
    <Card
      elevation={0}
      onClick={() => props.handleCardClick(props.product.id)}
      sx={{
        width: "100%",
        maxWidth: "312px",
        backgroundColor: "transparent",
        background:
          props.exchangeMode === 1
            ? "linear-gradient(90deg, #450D5F 50%, #170728 100%)"
            : props.exchangeMode === 2
            ? "linear-gradient(271deg, #695128 0.49%, #160F31 49.34%, #E0D49D 96.2%)"
            : "linear-gradient(271deg, #FC6964 0.49%, #F36E1E 49.34%, #FCC442 96.2%)",
        padding: "2px",
        boxSizing: "border-box",
        borderRadius: "10px",
        alignSelf: "stretch",
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
        "&:active": {
          opacity: 0.6,
        },
      }}
    >
      <Box
        style={{
          backgroundColor: props.dark ? "#160F31" : "white",
          width: "100%",
          height: "100%",
          borderRadius: "7px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <CardMedia
            component="img"
            style={{
              width: "156px",
              height: "280px",
            }}
            image={props.product.thumbnail}
            alt={`Product ${props.product.id}`}
          />
          <CardContent
            sx={{
              width: "calc(100% - 32px)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Grid
              item
              style={{
                width: "63%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: props.dark ? "white" : "#160F31",
                  fontWeight: "700",
                }}
              >
                {props.product.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: props.dark ? "white" : "#160F31" }}
              >
                {props.product.cellarName}
              </Typography>
              {!props.exchangeMode === 1 ? (
                <>
                  <Typography
                    variant="caption"
                    sx={{ color: props.dark ? "white" : "#160F31" }}
                  >
                    {t("marketplace.boxes1") +
                      props.product.bottleQuantity +
                      t("marketplace.boxes2")}
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              item
              style={{
                textAlign: "right",
                width: "35%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "end",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: props.dark ? "white" : "#160F31",
                  fontWeight: "700",
                }}
              >
                {props.exchangeMode === 2
                  ? `${props.product.localPrice}`
                  : props.product?.price}
              </Typography>
              {/* <Typography
                variant="caption"
                sx={{
                  color: props.dark ? "white" : "#160F31",
                  fontWeight: "400",
                }}
              >
                {props.exchangeMode === 2 && `${props.product?.liters}L`}
              </Typography> */}

              {props.exchangeMode !== 1 ? (
                <>
                  <Typography
                    variant="caption"
                    sx={{ color: props.dark ? "white" : "#160F31" }}
                  >
                    {props.exchangeMode === 2
                      ? `${t("marketplace.installment")} ${
                          props.product?.lotNumber
                        }`
                      : `${props.product?.quantity} ${t(
                          "marketplace.available"
                        )}`}
                  </Typography>
                </>
              ) : (
                ""
              )}
              <Typography variant="caption">
                {props.exchangeMode === 2 &&
                  `${props.product.totalLots} lotes de ${props.product?.liters}L disponibles`}
              </Typography>
            </Grid>
          </CardContent>
        </Box>
        {props.exchangeMode === 1 ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "14px",
              }}
            >
              {`${t("marketplace.seller")}:`}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "14px",
              }}
            >
              {`${props.product?.user?.name}`}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "14px",
              }}
            >
              {`${t("marketplace.token")}: ${props.product?.tokenNumber}`}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "black",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "14px",
              }}
            >
              {`${t("marketplace.endDate")}: ${
                props.product?.endDate?.date?.split(" ")[0]
              }`}
            </Typography>
          </Box>
        ) : null}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              props.exchangeMode === 1
                ? "linear-gradient(90deg, #450D5F 50%, #170728 100%)"
                : props.exchangeMode === 2
                ? "linear-gradient(271deg, #695128 0.49%, #160F31 49.34%, #E0D49D 96.2%)"
                : "linear-gradient(271deg, #FC6964 0.49%, #F36E1E 49.34%, #FCC442 96.2%)",
            backgroundColor:
              props.exchangeMode === 1 ? "#450D5F" : "transparent",
            padding: "0.5rem",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "14px",
            }}
          >
            {props.exchangeMode === 2
              ? t("marketplace.adquireCollateral")
              : t("marketplace.buy")}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default MarketplaceCard;
