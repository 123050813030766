export function hashEllipsis(str) {
  // Obtener la longitud de la cadena
  const len = str?.length;
  if (len && len >= 10) {
    // Obtener los primeros 5 dígitos y los últimos 4 dígitos
    const before = str.slice(0, 8);
    const after = str.slice(len - 8);

    return before + "..." + after;
  } else {
    return str;
  }
}
