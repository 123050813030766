import apiToken from "../apiToken";

class CellarAvailablesRepository {
  async getCellarsAvailableList(tokenId) {
    try {
      const response = await apiToken.get(
        `/profile/collaterals/requests?tokenId=${tokenId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default CellarAvailablesRepository;
