import * as React from "react";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { hashEllipsis } from "../../../utils/ellipsisId";
import CellarAvailablesRepository from "../../../data/repository/CellarAvailablesRepository";
import ProductRepository from "../../../data/repository/ProductRepository";
import CollateralRepository from "../../../data/repository/CollateralRepository";
import { Link } from "react-router-dom";
import SuccessDialog from "../SuccessDialog";
import ErrorDialog from "../ErrorDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#160F31",
    color: "#F36E1D",
    fontWeight: 900,
    /* borderBottom: "1px solid grey", */
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f2f2f2",
    /* color: "#F36E1D", */
    /* color: theme.palette.common.black, */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CollapsibleTable() {
  const productRepository = new ProductRepository();
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [openRows, setOpenRows] = React.useState({ 0: true });
  const [areRowsLoading, setAreRowsLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const cellarRepository = new CellarAvailablesRepository();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSingle, setAnchorElSingle] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCollateralId, setSelectedCollateralId] = useState(null);
  const [openModalBurn, setOpenModalBurn] = useState(false);
  const [openModalTransfer, setOpenModalTransfer] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [cellars, setCellars] = useState([]);
  const [getWineriesLoading, setGetWineriesLoading] = useState(false);
  const [cellarSelectedToTransfer, setSelectedCellarToTransfer] = useState("");
  const [success, setSuccess] = useState(null);
  const [price, setPrice] = useState();
  const [transferCollateralLoading, setTransferCollateralLoading] =
    useState(false);
  const handleClick = (event, collateralId) => {
    handleClose();
    setAnchorEl(event.currentTarget);
    setSelectedCollateralId(collateralId);
  };
  const collateralRepository = new CollateralRepository();
  const [error, setError] = useState(null);
  const [putOnSaleLoading, setPutOnSaleLoading] = useState(false);
  const [putOffSaleLoading, setPutOffSaleLoading] = useState(false);
  const [cancelInstallmentLoading, setCancelInstallmentLoading] =
    useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCollateralId(null);
  };

  const handleModalTransfer = (id) => {
    setOpenModalTransfer(true);
    fetchAvailableCellars(id);
  };

  const fetchAvailableCellars = async (id) => {
    setGetWineriesLoading(true);
    try {
      const response = await cellarRepository.getCellarsAvailableList(id);
      setCellars(response?.map((cellar) => cellar));
    } catch (error) {
      console.error("Error fetching cellars:", error);
    } finally {
      setGetWineriesLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSelectedCellarToTransfer(value);
  };

  const fetchFinancialCollaterals = async (page) => {
    setAreRowsLoading(true);
    try {
      const collaterals = await productRepository.findFinancialCollaterals(
        page ?? pageSelected
      );
      setRows(collaterals.rows);
      setNumberOfPages(
        Math.ceil(
          Number(collaterals.pagination?.count / collaterals.pagination?.limit)
        )
      );
    } catch (error) {
      console.error("Error al obtener colaterales del usuario:", error);
    }
    setAreRowsLoading(false);
  };

  const handleTransfer = async () => {
    setTransferCollateralLoading(true);
    if (selectedCollateralId) {
      const result = await collateralRepository.transferCollateral(
        selectedCollateralId,
        cellarSelectedToTransfer
      );

      if (result.success) {
        setSuccess(t("myNFTs.modals.transfer.success"));
        fetchFinancialCollaterals();
      } else {
        setError(
          result.message === "collateral.transfer.error.00001"
            ? t("myNFTs.transfer.error1")
            : result.message === "collateral.transfer.error.00002"
            ? t("myNFTs.transfer.error2")
            : result.message === "collateral.transfer.error.00003"
            ? t("myNFTs.transfer.error3")
            : result.message === "collateral.transfer.error.00004"
            ? t("myNFTs.transfer.error4")
            : result.message === "collateral.transfer.error.00005"
            ? t("myNFTs.transfer.error5")
            : result.message === "collateral.transfer.error.00006"
            ? t("myNFTs.transfer.error6")
            : "Error: " + result.message
        );
      }
      setOpenModalTransfer(false);
      handleClose();
    }
    setTransferCollateralLoading(false);
  };

  const handleCancelSale = async () => {
    setPutOffSaleLoading(true);
    if (selectedCollateralId) {
      const result = await collateralRepository.cancelSale(
        selectedCollateralId
      );

      if (result.success) {
        setSuccess(t("myNFTs.modals.cancel.success"));
        fetchFinancialCollaterals();
      } else {
        setError(
          result.message === "sale.error.00001"
            ? t("myNFTs.error1")
            : result.message === "sale.error.00002"
            ? t("myNFTs.error2")
            : result.message === "sale.error.00003"
            ? t("myNFTs.error3")
            : result.message === "sale.error.00004"
            ? t("myNFTs.error4")
            : result.message === "sale.error.00005"
            ? t("myNFTs.error5")
            : result.message === "sale.error.00006"
            ? t("myNFTs.error6")
            : result.message === "sale.error.00007"
            ? t("myNFTs.error7")
            : result.message === "token_not_found"
            ? t("myNFTs.error9")
            : "Error: " + result.message
        );
      }
      setOpenModalCancel(false);
      handleClose();
    }
    setPutOffSaleLoading(false);
  };

  const handleSell = async () => {
    setPutOnSaleLoading(true);
    if (selectedCollateralId) {
      const result = await collateralRepository.putUpInSale(
        selectedCollateralId,
        price
      );

      if (result.success) {
        setSuccess(t("myNFTs.modals.sell.success"));
        fetchFinancialCollaterals();
      } else {
        setError(
          result.message === "sale.error.00001" ? (
            t("myNFTs.error1")
          ) : result.message === "sale.error.00002" ? (
            t("myNFTs.error2")
          ) : result.message === "sale.error.00003" ? (
            <Trans
              i18nKey="myNFTs.error3"
              components={[
                <Link href="/profile/user-data" style={{ margin: "1px" }} />,
              ]}
            />
          ) : result.message === "sale.error.00004" ? (
            t("myNFTs.error4")
          ) : result.message === "sale.error.00005" ? (
            t("myNFTs.error5")
          ) : result.message === "sale.error.00006" ? (
            t("myNFTs.error6")
          ) : result.message === "sale.error.00007" ? (
            t("myNFTs.error7")
          ) : result.message === "sale.error.00008" ? (
            t("myNFTs.error8")
          ) : result.message === "token_not_found" ? (
            t("myNFTs.error9")
          ) : result.message === "token_paid" ? (
            t("myNFTs.error10")
          ) : (
            "Error: " + result.message
          )
        );
      }
      setOpenModal(false);
      handleClose();
    }
    setPutOnSaleLoading(false);
  };

  const handleBurnCollateral = async () => {
    setCancelInstallmentLoading(true);
    if (selectedCollateralId) {
      const result = await collateralRepository.cancelInstallment(
        selectedCollateralId
      );

      if (result.success) {
        setSuccess(t("myNFTs.modals.burn.success"));
        fetchFinancialCollaterals();
      } else {
        setError(
          result.message === "sale.error.00001"
            ? t("myNFTs.error1")
            : result.message === "sale.error.00002"
            ? t("myNFTs.error2")
            : result.message === "sale.error.00003"
            ? t("myNFTs.error3")
            : result.message === "sale.error.00004"
            ? t("myNFTs.error4")
            : result.message === "sale.error.00005"
            ? t("myNFTs.error5")
            : result.message === "sale.error.00006"
            ? t("myNFTs.error6")
            : result.message === "sale.error.00007"
            ? t("myNFTs.error7")
            : result.message === "token_not_found"
            ? t("myNFTs.error9")
            : "Error: " + result.message
        );
      }
      setOpenModalBurn(false);
      handleClose();
    }
    setCancelInstallmentLoading(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenModalCancel(false);
    setOpenModalTransfer(false);
    setOpenModalBurn(false);
  };

  React.useEffect(() => {
    fetchFinancialCollaterals();
  }, []);

  const handleToggleRow = (index) => {
    setOpenRows((prev) => ({
      ...prev,
      [index]: !prev[index], // Alternar solo el estado del índice actual
    }));
  };

  const clickPaginationColaterals = async (event, page) => {
    if (!areRowsLoading) {
      fetchFinancialCollaterals(page).then(() => setPageSelected(page));
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        {rows.length > 0 ? (
          <Table aria-label="collapsible table" sx={{ minWidth: "100%" }}>
            {rows.map((m, index) => (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleToggleRow(index)}
                      >
                        {openRows[index] ? (
                          <KeyboardArrowUpIcon
                            sx={{
                              background: "#F36E1D",
                              borderRadius: "55px",
                              padding: "5px",
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={{
                              background: "#F36E1D",
                              borderRadius: "55px",
                              padding: "5px",
                            }}
                          />
                        )}
                      </IconButton>
                      <Typography variant="body1" fontWeight="500">
                        {m.cellar.name}: {m.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                    {m.lot.map((lot, lotIndex) => (
                      <>
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell colSpan={1} align="left">
                                  <span style={{ fontWeight: 600 }}>
                                    Cuota:
                                  </span>{" "}
                                  {lot.lotNumber}
                                </StyledTableCell>
                                <StyledTableCell colSpan={4} />
                                <StyledTableCell colSpan={1} align="right">
                                  <>
                                    {lot.actions && !lot.isOnSale && (
                                      <span
                                        style={{
                                          color: "#F36E1E",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleClick(event, lot.id);
                                        }}
                                      >
                                        {t("myNFTs.acciones")}
                                      </span>
                                    )}
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={selectedCollateralId === lot.id}
                                      onClose={handleClose}
                                    >
                                      {!lot.isOnSale && (
                                        <>
                                          <MenuItem
                                            key="sell"
                                            onClick={() => setOpenModal(true)}
                                          >
                                            {/* PONER EN VENTA */}
                                            {t("myNFTs.confirmarVenta")}
                                          </MenuItem>
                                          {!lot.isOnSale && !lot.isPaid && (
                                            <MenuItem
                                              key="cancel"
                                              onClick={() =>
                                                setOpenModalBurn(true)
                                              }
                                            >
                                              {/* CANCELAR CUOTA */}
                                              {t("myNFTs.cancelarPlazo")}
                                            </MenuItem>
                                          )}
                                        </>
                                      )}
                                    </Menu>
                                  </>
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {lot.tokens.map((token, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "& td": {
                                      paddingLeft: "3rem",
                                      textTransform: "capitalize",
                                    },
                                  }}
                                >
                                  <TableCell colSpan={1} align="left">
                                    <span style={{ fontWeight: 600 }}>
                                      TOKEN
                                    </span>{" "}
                                    {lot.lotNumber + "." + token.lotSubNumber}
                                  </TableCell>
                                  <TableCell colSpan={1} align="left">
                                    <span style={{ fontWeight: 600 }}>ID:</span>{" "}
                                    {hashEllipsis(token.id)}
                                  </TableCell>
                                  <TableCell colSpan={1} align="left">
                                    <span style={{ fontWeight: 600 }}>
                                      {t("myNFTs.price")}:
                                    </span>{" "}
                                    ${token.installmentAmount}
                                  </TableCell>
                                  <TableCell colSpan={1} align="left">
                                    <span style={{ fontWeight: 600 }}>
                                      {t(
                                        "myNFTs.wineryCollateralsTable.liters"
                                      )}
                                      :
                                    </span>{" "}
                                    {token.liters} L
                                  </TableCell>
                                  <TableCell colSpan={1} align="left">
                                    <span style={{ fontWeight: 600 }}>
                                      {" "}
                                      {t("myNFTs.estado")}:
                                    </span>{" "}
                                    {token.isBuyback ? (
                                      <span style={{ color: "green" }}>
                                        {t("myNFTs.buybackVendido")}
                                      </span>
                                    ) : token.inSale ? (
                                      <span style={{ color: "green" }}>
                                        {t("myNFTs.enVenta")}
                                      </span>
                                    ) : !token.isInstallmentPaid ? (
                                      <span style={{ color: "orange" }}>
                                        {t("myNFTs.pendientePago")}
                                      </span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        {t("myNFTs.pagado")}
                                      </span>
                                    )}
                                  </TableCell>

                                  <TableCell colSpan={1} align="right">
                                    {token.inSale ? (
                                      <>
                                        <span
                                          style={{
                                            color: "#F36E1E",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleClick(event, token.id);
                                          }}
                                        >
                                          {t("myNFTs.acciones")}
                                        </span>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={
                                            selectedCollateralId === token.id
                                          }
                                          onClose={handleClose}
                                        >
                                          <MenuItem
                                            key="transfer"
                                            onClick={() =>
                                              handleModalTransfer(token.id)
                                            }
                                          >
                                            {t("myNFTs.transferirColateral")}
                                          </MenuItem>
                                        </Menu>
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ))}
                  </Collapse>
                </TableBody>
              </>
            ))}

            {/* MODAL SELL */}
            <Dialog open={openModal} onClose={handleCloseModal}>
              <DialogTitle>{t("myNFTs.modals.sell.title")}</DialogTitle>
              <DialogContent style={{ textAlign: "center" }}>
                <Typography variant="body1">
                  {t("myNFTs.modals.sell.sub_title")}
                </Typography>
              </DialogContent>
              <DialogContent>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    fullWidth
                    type="number"
                    label={t("myNFTs.modals.sell.price")}
                    name="residence"
                    value={price}
                    slotProps={{ inputLabel: { shrink: true } }}
                    onChange={(e) => setPrice(e.target.value)}
                    InputProps={{
                      style: { borderRadius: "55px" },
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  className={"btn-outline-gradient"}
                >
                  {t("myNFTs.atras")}
                </Button>
                <Button
                  onClick={putOnSaleLoading ? null : handleSell}
                  className={"btn-gradient"}
                  disabled={putOnSaleLoading || !price}
                >
                  {t("myNFTs.modals.sell.confirm")}
                </Button>
              </DialogActions>
            </Dialog>

            {/* MODAL CANCEL SELL */}
            <Dialog open={openModalCancel} onClose={handleCloseModal}>
              <DialogTitle>{t("myNFTs.modals.cancel.title")}</DialogTitle>
              <DialogContent style={{ textAlign: "center" }}>
                <Typography variant="body1">
                  {t("myNFTs.modals.cancel.sub_title")}
                </Typography>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  className={"btn-outline-gradient"}
                >
                  {t("myNFTs.atras")}
                </Button>
                <Button
                  onClick={putOffSaleLoading ? null : handleCancelSale}
                  className={"btn-gradient"}
                  disabled={putOffSaleLoading}
                >
                  {t("myNFTs.modals.cancel.confirm")}
                </Button>
              </DialogActions>
            </Dialog>

            {/* MODAL TRANSFER */}
            <Dialog open={openModalTransfer} onClose={handleCloseModal}>
              <DialogTitle>{t("myNFTs.modals.transfer.title")}</DialogTitle>
              <DialogContent style={{ textAlign: "center" }}>
                <Typography variant="body1">
                  {t("myNFTs.modals.transfer.sub_title")}
                </Typography>
              </DialogContent>
              <DialogContent>
                {getWineriesLoading ? (
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", padding: "1rem" }}
                  >
                    {t("myNFTs.modals.transfer.loadingWineries")}
                  </Typography>
                ) : (
                  <>
                    {cellars.length === 0 ? (
                      <p style={{ color: "red" }}>Sin bodegas disponibles</p>
                    ) : (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel sx={{ background: "white" }}>
                          {t("myNFTs.modals.transfer.select")}
                        </InputLabel>

                        <Select
                          name="cellars"
                          onChange={handleInputChange}
                          input={
                            <OutlinedInput
                              style={{
                                borderRadius: "55px",
                              }}
                            />
                          }
                          label={t("marketplace.cellars")}
                        >
                          {cellars?.map((cellar) => (
                            <MenuItem key={cellar.id} value={cellar.id}>
                              {cellar.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  className={"btn-outline-gradient"}
                >
                  {t("myNFTs.atras")}
                </Button>
                <Button
                  onClick={
                    transferCollateralLoading || getWineriesLoading
                      ? null
                      : handleTransfer
                  }
                  className={"btn-gradient"}
                  disabled={
                    transferCollateralLoading ||
                    getWineriesLoading ||
                    cellars.length === 0
                  }
                >
                  {t("myNFTs.modals.transfer.confirm")}
                </Button>
              </DialogActions>
            </Dialog>

            {/* MODAL BURN */}
            <Dialog open={openModalBurn} onClose={handleCloseModal}>
              <DialogTitle>{t("myNFTs.modals.burn.title")}</DialogTitle>
              <DialogContent style={{ textAlign: "center" }}>
                <Typography variant="body1">
                  {t("myNFTs.modals.burn.sub_title")}
                </Typography>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  className={"btn-outline-gradient"}
                >
                  {t("myNFTs.atras")}
                </Button>
                <Button
                  onClick={
                    cancelInstallmentLoading ? null : handleBurnCollateral
                  }
                  className={"btn-gradient"}
                  disabled={cancelInstallmentLoading}
                >
                  {t("myNFTs.modals.burn.confirm")}
                </Button>
              </DialogActions>
            </Dialog>
          </Table>
        ) : (
          <Box display="flex" justifyContent={"center"} p={3}>
            <Typography>{t("myNFTs.collateralsNoResults")}</Typography>
          </Box>
        )}
      </TableContainer>
      {rows?.length > 0 && (
        <Box display="flex" mt={2} justifyContent={"center"}>
          <Pagination
            count={numberOfPages}
            defaultPage={pageSelected}
            siblingCount={2}
            onChange={clickPaginationColaterals}
            disabled={areRowsLoading}
          />
        </Box>
      )}

      <SuccessDialog
        open={!!success}
        onClose={() => setSuccess(null)}
        alertTitle={t("myNFTs.success")}
        alertMessage={success}
        dark={false}
      />

      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        alertTitle={t("myNFTs.error")}
        alertMessage={error}
        dark={false}
      />
    </>
  );
}
