import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const WineryOwnCollateralsComponent = (rows) => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper} style={{ marginTop: "3rem" }}>
      {rows.rows.length > 0 ? (
        <Table aria-label="tabla de winenfts">
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.vino")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.price")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.liters")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.plazo")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.estado")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.rows?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.productCollateral.name}</TableCell>
                <TableCell>
                  ${row.productCollateral?.installmentAmount}
                </TableCell>
                <TableCell>{row?.liters}L</TableCell>
                <TableCell>{row.installmentNumber}</TableCell>
                <TableCell>
                  {row.isInstallmentPaid === "pending" ? (
                    <span style={{ color: "orange" }}>
                      {t("myNFTs.pendientePago")}
                    </span>
                  ) : row.isInstallmentPaid === "canceled" ? (
                    <span style={{ color: "red" }}>{t("myNFTs.canceled")}</span>
                  ) : (
                    <span style={{ color: "green" }}>{t("myNFTs.pagado")}</span>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        t("myNFTs.collateralsNoResults")
      )}
    </TableContainer>
  );
};

export default WineryOwnCollateralsComponent;
