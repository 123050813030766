import api from "../api";
class ProductsTypesRepository {
  async getProductsTypes() {
    try {
      const response = await api.get("/product-type/list");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default ProductsTypesRepository;
