import apiToken from "../apiToken";

class MarketplaceStatsRepository {
  async getMarketplaceStats() {
    try {
      const response = await apiToken.get("/marketplace/collaterals/stats");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default MarketplaceStatsRepository;
